import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import FreeTrialSignup from '../../../components/FreeTrialSignup';

import './style.scss';

const Page = () => {
    return (
        <Layout navTheme="light">
            <Seo
                title="New release updates | GlassHive"
                description="At GlassHive, we are continuously making improvements to the platform to enhance user experience and functionality. Check out the newest features!"
            />
            <div className="release-notes-page">
                <Container className="container-1">
                    <Row className="center">
                        <Col className="centered-text">
                            <h1 className="white-text">
                                GlassHive new release updates
                            </h1>
                            <p className="hero-subtext white-text">
                                October 2022
                            </p>
                        </Col>
                    </Row>
                    <img
                        className="white-divider"
                        src={require('../../../images/features/lead-scrubbing/white-divider.svg')}
                        alt="white divider"
                    />
                </Container>
                <Container className="container-2">
                    <Row>
                        <Col className="three-fifth video-column">
                            <iframe
                                width="560"
                                height="315"
                                src="https://www.youtube.com/embed/BgfnXCFtPgs"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                            ></iframe>
                        </Col>
                        <Col className="two-fifth">
                            <h4 className="centered-mobile">
                                The latest updates from GlassHive
                            </h4>
                            <p>
                                <b>We're back</b> with another Glasshive release
                                notes video. Here we'll discuss all the updates,
                                features, or quality of life improvements we
                                have made in the Hive. Let's get into it!
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-3">
                    <Row>
                        <Col className="two-fifth">
                            <h4 className="centered-mobile">
                                Updates & Improvements
                            </h4>
                            <ul>
                                <li>Updated company profile card design</li>
                                <li>Updated contact profile card design</li>
                                <li>
                                    Updated location and backend functionality
                                    of Partnerships for Partner accounts
                                </li>
                                <li>Updated 2 step authentication page logo</li>
                                <li>
                                    Removed Birthdate label and cell within the
                                    Custom Contact/Company Field widget
                                </li>
                                <li>
                                    Updated tasks to only load 50 tasks at a
                                    time
                                </li>
                                <li>Updated logo in CW integration pages</li>
                                <li>
                                    Updated Honey logo on Integrations card
                                    (bubbles)
                                </li>
                            </ul>

                            <h4 className="centered-mobile">Bug fixes</h4>
                            <ul>
                                <li>
                                    Fixed bug where unassigned tasks would break
                                    the table
                                </li>
                                <li>
                                    Fixed bug where long company description is
                                    not wrapping on Company Profile
                                </li>
                                <li>
                                    Fixed filter menu that would break when
                                    using more than 8 filters
                                </li>
                                <li>
                                    Fixed bug where phone number wasn't showing
                                    up on company details card
                                </li>
                                <li>
                                    Fixed bug where Salesperson Dashboard
                                    Opportunity board does not update when
                                    choosing different salesperson
                                </li>
                                <li>
                                    Fixed bug where tasks weren't being sorted
                                    correctly
                                </li>
                            </ul>
                        </Col>
                        <Col className="three-fifth form-column">
                            <FreeTrialSignup titleText="Sign up for a free account today!" />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default Page;
